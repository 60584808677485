@import "_variables.scss";
@import "_mixins.scss";

@mixin customStyle() {
  @include box-sizing(border-box);
  border: none;
  color: #888888;
  background: #fff;
}

@mixin buttonStyle() {
  
  @include transition(.3s background ease);
  display: block;
  color: #FFF;
  background: $primary;
  font-weight: 400;
  font-size: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  &:hover {
    background: $secondary;
  }
}



/* Control Holder */
.booking-form {
  position: relative;
  float: right;
  clear: both;
  display: inline-block;
  width: 470px;
  margin-top: 41px;
  padding: 30px 30px 23px;
  background: $secondary2;
  @include border-radius(3px);
  @include box-sizing(border-box);
  input,
  textarea {
    outline: none;
  
    padding: 12px 10px 15px;
    font-size: 16px;
    line-height: 22px;
    height: 50px;
    width: 100%;
    border: 2px solid lighten(saturate(adjust_hue(#888888, 0), 6.382978723404229%), 37.45098039215686%);
    @include border-radius(3px);
    @include box-sizing(border-box);
  }

  textarea {
    min-height: 200px;
    resize: none;
  }

  input + input {
    margin-top: 10px;
  }

  .cntr{
    @include clearfix;

    .col{
      float: left;
      width: 32%;

      & + .col{
        margin-left: 2%;
      }
    }
  }

  a[data-type='submit']{
    margin-top: 30px;
    padding: 21px 17px;

    & + * {
      margin-top: 19px;
    }
  }

  @media (max-width: 1199px) {
    .cntr{
      .col{
        float: none;
        width: 100%;
      }

      .col + .col{
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 749px) {
    width: 100%;
    padding: 30px 10px;
  }
}

.tmInput, .tmDatepicker, .tmSelect{
  position: relative;

  &:before{
    position: absolute;
    top: 50%;
    left: 14px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 22px;
    color: $default;
  }

  input{
    padding-left: 19px;
  }
}

.tmDatepicker, .tmSelect{
  display: block;
  input{
    padding-right: 30px;
  }
  &:after{
    content: $fa-angle-down;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 15px;
    font: 400 14px/20px $fa;
    color: #c9c9c9;
  }
}

.controlHolder + .controlHolder {
  margin-top: 10px;
}

.controlHolder {
  position: relative;
}

.controlHolder + .cntr{
  margin-top: 10px;
}

.cntr + .btn{
  margin-top: 20px;
}

/* Messages */
.error-message {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: normal;
  text-align: right;
  width: 100%;
  font-size: 10px;
  line-height: 10px;
  z-index: 4;
  color: #FE5353;
}

.success-message {
  position: absolute;
  top: 100%;
  text-align: left;
  margin: 5px 0px;
  color: #13FF63;
  font-size: 14px;
  line-height: 15px;
}

/* transformSelect CSS */
.tmSelect {
  clear: both;
  width: 100%;
  margin: 0px;
  li {
    position: relative;
    list-style: none;
  }
  span, input[type=text] {
    position: relative;
    cursor: pointer;
    display: block;
    left: 0;
    top: 0;
    margin: 0;
  }

  &:before{
    content: '\f017';
    font: 400 14px/20px $fa;
    z-index: 1;
  }

  &.auto {
    cursor: pointer;
    span {
      background: transparent;
    }
    padding: 0;
    margin: 0 0 20px 0;
    line-height: 20px;
    @include customStyle();
    
    @include box-sizing(border-box);
    ul {
      margin: 1px 0 0;
      @include box-sizing(border-box);
      width: 100%;
      background: #F9F9F9;
    }
    > li {
      &:first-child > span {
        padding: 7px 12px;
        width: 35px;
        height: 100%;
        @include box-sizing(border-box);
      }
    }
  }
  ul, .transformSelectDropdown {
    position: absolute;
    width: 100%;
    max-height: 210px;
    padding: 7px 0px;
    margin: 0px;
    @include customStyle();
    
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    @include box-sizing(border-box);
    box-shadow: none;
    background: #F9F9F9;
    z-index: 10;
    span {
      border: 0;
      color: #000;
      padding: 5px 13px;
      &:hover {
        background: #CCC;
      }
    }
  }
  ul li input[type=checkbox] {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
    cursor: pointer;
  }
}

/* Radio */
.trans-element-radio {
  display: inline-block;
  display: block;
  zoom: 1;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.tmRadio {
  padding-left: 0;
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  p {
    padding-bottom: 0;
    margin-bottom: 3px;
  }
  input {
    margin-right: 5px;
  }
  .unchecked, .checked {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    &:after {
      content: '\f10c';
      position: relative;
      top: 2px;
      
    }
  }
  .checked {
    &:after {
      content: '\f05d';
    }
  }
}

/* Checkbox */
.trans-element-checkbox {
  cursor: pointer;
  zoom: 1;
  span {
    padding-left: 40px;
  }
  &.checked {
    &:after {
      content: '\f046';
    }
  }
  &:after {
    content: '\f096';
    position: relative;
    display: inline-block;
    top: 2px;
    width: 17px;
    
  }
  &.unchecked {
    &:after {
      content: '\f096';
    }
  }
}

.tmCheckbox, .tmRadio {
  margin-left: 0;
  padding-left: 0;
  padding: 0;
  span {
    position: relative;
    margin-right: 10px;
  }
}

.ui-datepicker {
  padding: 10px;
  width: 282px;
  font-size: 13px;
  display: none;
  z-index: 100 !important;
  @include customStyle();
  background: #F9F9F9;
  a {
    color: #777;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.ui-datepicker-prev, .ui-datepicker-next {
  cursor: pointer;
  float: left;
}

.ui-datepicker-next {
  float: right;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar {
  margin: 10px 11px;
  td {
    padding: 1px 10px;
    text-align: center;
  }
  .ui-datepicker-current-day, .ui-datepicker-today {
    padding: 3px;
    background: $primary;
    color: #FFF;

    a{
      color: #FFF;
    }
  }
}

button.ui-state-default {
  @include reset-button;
  padding: 4px 6px;
  background: $primary;
  color: #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  @include transition(.3s all ease);

  &[data-handler='today']{
    display: none;
  }

  &:hover{
    background: $secondary2;
  }
}

.ui-datepicker-close {
  float: right;
}