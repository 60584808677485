//========================================================
//                     Variables
//=========================================================

//Backgrounds
$header-bg: #fff;
$content-bg: #fff;
$footer-bg: #fff;

//Colors
$default: #888;
$primary: #5dc761;
$secondary: #333;
$secondary2: #f4f4f4; 
$contrast: white;

//Fonts
$default-font: 'Roboto', sans-serif;
